@import '../config/settings.scss';

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
    line-height: 1.1;
}

h1,
h2,
h3,
h4,
h5 {
  color: $swiss-color-red;
}

.headline {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: $swiss-color-red;
    line-height: 1;
}

p {
    line-height: 1.2;
}