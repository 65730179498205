/**
 * @license
 * MyFonts Webfont Build ID 3615201, 2018-07-26T05:24:47-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FuturaStd-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/futura/pro-light/
 * Copyright: Copyright &#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 * 
 * Webfont: FuturaStd-Book by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/futura/pro-book/
 * Copyright: Copyright &#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 * 
 * Webfont: FuturaStd-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/futura/pro-heavy/
 * Copyright: Copyright &#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3615201
 * Licensed pageviews: 250,000
 * 
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */

  
@font-face {font-family: 'FuturaStd-Light';src: url('webfonts/3729E1_0_0.eot');src: url('webfonts/3729E1_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3729E1_0_0.woff2') format('woff2'),url('webfonts/3729E1_0_0.woff') format('woff'),url('webfonts/3729E1_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'FuturaStd-Book';src: url('webfonts/3729E1_1_0.eot');src: url('webfonts/3729E1_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3729E1_1_0.woff2') format('woff2'),url('webfonts/3729E1_1_0.woff') format('woff'),url('webfonts/3729E1_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'FuturaStd-Heavy';src: url('webfonts/3729E1_2_0.eot');src: url('webfonts/3729E1_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3729E1_2_0.woff2') format('woff2'),url('webfonts/3729E1_2_0.woff') format('woff'),url('webfonts/3729E1_2_0.ttf') format('truetype');}
 