.intro {
    margin-bottom: 40px;
    max-width: 680px;
    display: inline-block;
    line-height: 1.3;
    color: #000;

    a {
        color: $swiss-color-red;
    }
}